(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/share-coupon/assets/javascripts/share-button.js') >= 0) return;  svs.modules.push('/components/tipsen/share-coupon/assets/javascripts/share-button.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  default: TippyReact
} = Tippy;
const {
  useState,
  useRef,
  useEffect,
  useCallback
} = React;
const {
  log
} = svs.core;
const {
  ReactButton
} = svs.ui;
const logger = log.getLogger('tipsen:share-button');
const ShareStatus = {
  None: 'None',
  CopiedToClipboard: 'CopiedToClipboard',
  CopiedToClipboardFallback: 'CopiedToClipboardFallback',
  Shared: 'Shared',
  Text: 'Text'
};
const noop = () => {};
const defaultShareConfig = {
  title: 'Spela hos Svenska Spel S&C',
  text: "Spela hos Svenska Spel S&C",
  url: '!!THIS WILL BE REPLACED!!!'
};
const defaultShareMessages = Object.values(ShareStatus).reduce((texts, status) => _objectSpread(_objectSpread({}, texts), {}, {
  [status]: 'Texten delades'
}), {});

const fallbackCopyTextToClipboard = text => new Promise((resolve, reject) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    const status = document.execCommand('copy');
    if (status) {
      resolve();
      return;
    }
    reject(new Error('Unable to copy url legacy way'));
  } catch (err) {
    reject(err);
  } finally {
    document.body.removeChild(textArea);
  }
});
const copyTextToClipboard = async text => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
    return ShareStatus.CopiedToClipboard;
  }
  await fallbackCopyTextToClipboard(text);
  return ShareStatus.CopiedToClipboardFallback;
};
const share = async (url, shareConfig) => {
  const canShare = navigator && navigator.canShare && navigator.canShare(shareConfig);
  if (canShare) {
    try {
      await navigator.share(_objectSpread(_objectSpread({}, shareConfig), {}, {
        url
      }));
      return ShareStatus.Shared;
    } catch (err) {
      logger.info("Share aborted: ".concat(err.message));
      if (err.name === 'AbortError') {
        return ShareStatus.None;
      }
    }
  }
  return copyTextToClipboard(url);
};
const ShareButton = _ref => {
  let {
    className,
    disabled,
    children,
    isTransparent,
    shareText,
    onShare = noop,
    shareConfig = defaultShareConfig,
    shareMessages = defaultShareMessages,
    size = 300
  } = _ref;
  const timeout = useRef();
  const [shareStatus, setShareStatus] = useState(ShareStatus.None);
  const isDisabled = disabled || !shareText;
  useEffect(() => () => {
    clearTimeout(timeout.current);
  });
  const onTippyShown = useCallback(() => {
    timeout.current = setTimeout(() => {
      setShareStatus(ShareStatus.None);
    }, 5000);
  }, []);
  const handleShare = async () => {
    let newShareStatus;
    try {
      newShareStatus = await share(shareText, shareConfig);
      onShare(true);
    } catch (err) {
      logger.info("Unable to share text: ".concat(err.message));
      newShareStatus = ShareStatus.Text;
      onShare(false);
    } finally {
      logger.info("Shared status: ".concat(newShareStatus));
      setShareStatus(newShareStatus);
    }
  };
  const classNames = ['share-button-btn qa-on-share'];
  if (shareStatus === ShareStatus.Text) {
    classNames.push('std-input std-input-100');
  }
  if (className && className.length > 0) {
    classNames.push(className);
  }
  const renderContent = () => {
    if (shareStatus === ShareStatus.Text) {
      return React.createElement(React.Fragment, null, React.createElement("input", {
        className: classNames.join(' '),
        name: "shareInput",
        readOnly: true,
        ref: node => {
          if (!node) {
            return;
          }
          node.focus();
          node.select();
        },
        type: "text",
        value: shareText
      }), shareMessages[shareStatus]);
    }
    return React.createElement(ReactButton, {
      block: true,
      className: classNames.join(' '),
      disabled: isDisabled,
      onClick: handleShare,
      size: size,
      thin: true,
      transparent: isTransparent
    }, children);
  };
  return React.createElement(TippyReact, {
    content: shareMessages[shareStatus],
    onShown: onTippyShown,
    theme: "light-border",
    trigger: "manual",
    visible: ![ShareStatus.None, ShareStatus.Shared, ShareStatus.Text].includes(shareStatus)
  }, React.createElement("p", null, renderContent()));
};
setGlobal('svs.components.tipsen.shareCoupon.ShareButton', ShareButton);
setGlobal('svs.components.tipsen.shareCoupon.ShareStatus', ShareStatus);

 })(window);